import { Component, OnInit } from '@angular/core';
import {User} from "../../../../shared/models/user/user.model";
import {Subject} from "rxjs";
import {AuthenticationService} from "../../../../shared/authentication/authentication-service.service";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.css']
})
export class ForbiddenComponent implements OnInit {

 user: User;
 stop$ = new Subject();


  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.authenticationService.getUser().pipe(takeUntil(this.stop$))
      .subscribe(value => {
        this.user = value;
      });
  }

  takeMeBack() {
    if (this.user) {
      window.location.href = '/dashboard';
    } else {
      this.authenticationService.login({prompt: 'login'});
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
