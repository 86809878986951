<div id="saveSuccess" tabindex="-1" aria-hidden="true" class="modal-sm custom-modal-width">
    <div>
        <div class="modal-content rounded rounded-3">
            <div class="modal-header text-center">
            </div>
            <div class="modal-body px-lg-4 py-lg-3 text-center" style="font-size: 1.2rem; color: #34495e">
                <div class="row g-3">
                    <div class="col-lg-6">
                        <ng-container *ngIf="defaultImage; else noImage">
                            <img src="{{defaultImage}}" width="300" alt="defaultImage">
                        </ng-container>
                        <ng-template #noImage>
                            <img src="assets/img/placeholder-img.svg" width="300" alt="defaultImage">
                        </ng-template>

                    </div>
                    <div class="col-lg-6">
                        <ng-container *ngIf="selectedImage; else noImage2">
                            <img src="{{selectedImage}}" width="300" alt="defaultImage">
                        </ng-container>
                        <ng-template #noImage2>
                            <img src="assets/img/placeholder-img.svg" width="300" alt="defaultImage">
                        </ng-template>
                    </div>
                </div>
                <div class="text-center mt-5 mb-2">
                    <button
                            (click)="close()"
                            class="btn btn-sm btn-primary mx-2"
                            style="background-color: #3498db; border-color: #3498db"
                    >
                        Done
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


