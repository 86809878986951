import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {User} from "../../../../shared/models/user/user.model";
import {AuthenticationService} from "../../../../shared/authentication/authentication-service.service";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  user: User;
  stop$ = new Subject();

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.getUser().pipe(takeUntil(this.stop$))
      .subscribe(value => {
        this.user = value;
      });
  }

  goBackHome(){
    if (this.user) {
      this.router.navigate(['/dashboard'])
    }else{
      this.router.navigate(['/'])
    }
  }

}
