/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    otherData?: Array<object>;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'MVRD' | 'VRPS' | 'SYSTEM_SUPPORT';
    export const AccountTypeEnum = {
        Mvrd: 'MVRD' as AccountTypeEnum,
        Vrps: 'VRPS' as AccountTypeEnum,
        SystemSupport: 'SYSTEM_SUPPORT' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'UPDATE_USER' | 'VIEW_USER' | 'DEACTIVATE_USER' | 'UPDATE_SETTING' | 'VIEW_SETTING' | 'APPROVE_IDENTITY_INVALIDATION' | 'APPROVE_IDENTITY_SUSPENSION' | 'INVALIDATE_IDENTITY' | 'SUSPEND_IDENTITY' | 'VIEW_AUDIT_TRAIL';
    export const PermissionsEnum = {
        CreateUser: 'CREATE_USER' as PermissionsEnum,
        UpdateUser: 'UPDATE_USER' as PermissionsEnum,
        ViewUser: 'VIEW_USER' as PermissionsEnum,
        DeactivateUser: 'DEACTIVATE_USER' as PermissionsEnum,
        UpdateSetting: 'UPDATE_SETTING' as PermissionsEnum,
        ViewSetting: 'VIEW_SETTING' as PermissionsEnum,
        ApproveIdentityInvalidation: 'APPROVE_IDENTITY_INVALIDATION' as PermissionsEnum,
        ApproveIdentitySuspension: 'APPROVE_IDENTITY_SUSPENSION' as PermissionsEnum,
        InvalidateIdentity: 'INVALIDATE_IDENTITY' as PermissionsEnum,
        SuspendIdentity: 'SUSPEND_IDENTITY' as PermissionsEnum,
        ViewAuditTrail: 'VIEW_AUDIT_TRAIL' as PermissionsEnum
    };
}


