/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DemographicDataPojo } from './demographic-data-pojo';


export interface EtlHistoryBufferPojo { 
    nin?: string;
    trackingId?: string;
    centralRecordId?: string;
    insertionTimestamp?: string;
    createdAt?: string;
    applicationType?: EtlHistoryBufferPojo.ApplicationTypeEnum;
    hasBiometrics?: boolean;
    jsonDemographicData?: DemographicDataPojo;
}
export namespace EtlHistoryBufferPojo {
    export type ApplicationTypeEnum = 'ER_NEW' | 'ER_MODIFICATION' | 'CR_NEW' | 'CR_MODIFICATION' | 'CR_REPLACEMENT' | 'CR_RENEWAL' | 'DEATH';
    export const ApplicationTypeEnum = {
        ErNew: 'ER_NEW' as ApplicationTypeEnum,
        ErModification: 'ER_MODIFICATION' as ApplicationTypeEnum,
        CrNew: 'CR_NEW' as ApplicationTypeEnum,
        CrModification: 'CR_MODIFICATION' as ApplicationTypeEnum,
        CrReplacement: 'CR_REPLACEMENT' as ApplicationTypeEnum,
        CrRenewal: 'CR_RENEWAL' as ApplicationTypeEnum,
        Death: 'DEATH' as ApplicationTypeEnum
    };
}


