/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { State } from './state';
import { Lga } from './lga';
import { Country } from './country';
import { PortalUser } from './portal-user';


export interface Address { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: Address.StatusEnum;
    deactivatedBy?: PortalUser;
    zipCode?: string;
    street?: string;
    houseNumber?: string;
    lga?: Lga;
    country?: Country;
    state?: State;
}
export namespace Address {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
}


