/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IdentitySearchResponsePojo { 
    id?: number;
    transactionId?: string;
    uin?: string;
    identityStatus?: IdentitySearchResponsePojo.IdentityStatusEnum;
    publishedToEme?: boolean;
    createdAt?: string;
}
export namespace IdentitySearchResponsePojo {
    export type IdentityStatusEnum = 'CLAIMED' | 'VALID' | 'INVALID' | 'REVOKED' | 'SUSPENDED';
    export const IdentityStatusEnum = {
        Claimed: 'CLAIMED' as IdentityStatusEnum,
        Valid: 'VALID' as IdentityStatusEnum,
        Invalid: 'INVALID' as IdentityStatusEnum,
        Revoked: 'REVOKED' as IdentityStatusEnum,
        Suspended: 'SUSPENDED' as IdentityStatusEnum
    };
}


