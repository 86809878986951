/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EtlAdjudicationDetailsPojo { 
    id?: number;
    nin?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    dateOfBirth?: string;
    phoneNumber?: string;
    enrollmentDate?: string;
    ninIssuanceDate?: string;
    biometricsId?: number;
    supportingDocument?: string;
    approvalStatus?: EtlAdjudicationDetailsPojo.ApprovalStatusEnum;
    approvalDate?: string;
    disApprovalDate?: string;
    centralRecordsId?: Array<string>;
    disapprovalMessage?: string;
    reviewerDetails?: string;
}
export namespace EtlAdjudicationDetailsPojo {
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


