/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { BwBinaryData } from './bw-binary-data';


export interface PortalUser { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalUser.StatusEnum;
    deactivatedBy?: PortalUser;
    firstName?: string;
    lastName?: string;
    otherNames?: string;
    email?: string;
    phoneNumber?: string;
    userId?: string;
    generatedPassword?: string;
    username?: string;
    dateOfBirth?: string;
    displayName?: string;
    gender?: PortalUser.GenderEnum;
    setupComplete?: boolean;
    emailVerified?: boolean;
    preferredName?: string;
    userVerified?: boolean;
    agentOnboarded?: boolean;
    nationalIdentificationNumber?: string;
    authenticationMethod?: PortalUser.AuthenticationMethodEnum;
    lastLoginAt?: string;
    image?: BwBinaryData;
    address?: Address;
}
export namespace PortalUser {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        Active: 'ACTIVE' as StatusEnum,
        Inactive: 'INACTIVE' as StatusEnum
    };
    export type GenderEnum = 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum,
        Unspecified: 'UNSPECIFIED' as GenderEnum,
        Other: 'OTHER' as GenderEnum
    };
    export type AuthenticationMethodEnum = 'FINGERPRINT' | 'PASSWORD';
    export const AuthenticationMethodEnum = {
        Fingerprint: 'FINGERPRINT' as AuthenticationMethodEnum,
        Password: 'PASSWORD' as AuthenticationMethodEnum
    };
}


