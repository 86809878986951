/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DemographicDataPojo { 
    centralRecordId?: string;
    surname?: string;
    firstName?: string;
    middleName?: string;
    birthDate?: string;
    birthCountry?: string;
    gender?: string;
    height?: string;
    telephoneNo?: string;
    nationality?: string;
}

