<div>
  <div class="container mt-2">
    <div>
      <div class="card border-0 mx-auto col-12 col-md-8"
           style="box-shadow: 2.381px 2.381px 11.905px 11.905px rgba(0, 0, 0, 0.03); border-radius: 29.956px; ">
        <div class="card-body">
          <div class="d-flex justify-content-center my-2">
            <div class="fw-bold fs-3 text-center" style="color: #00386D">
              {{ config?.title }}
            </div>
          </div>

          <div
            *ngIf="showAlertMessageTrigger"
            [ngClass]="{ anim: showAlertMessageTrigger }"
            [class.alert-success]="getMessageType() === AlertType.success"
            [class.alert-danger]="getMessageType() === AlertType.error"
            [class.alert-primary]="getMessageType() === AlertType.primary"
            [class.alert-info]="getMessageType() === AlertType.info"
            class="alert border-0 rounded-2 fs-14 text-center fw-bold"
          >
            {{ getAlertMessage() }}
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="card rounded-5 border-0" style="background: #ECF1FC;">
                <div class="card-body">
                  <div class="trails">
                    <div class="text-center" style="background: #00386D; border-radius: 12px;">
                      <div class="text-white py-1 fw-semibold px-3 fs-12">
                        Number of Trials: {{ config?.trialAttemptCount }}
                      </div>
                    </div>
                  </div>
                  <app-trial-tracker
                    [numberOfTrials]=""
                  ></app-trial-tracker>
                  <div class="mt-3 fs-12">
                    <span class="fw-bold">Note:</span> {{ config?.attemptInstruction }}
                  </div>
                </div>
              </div>

              <div class="mt-5">
                <lib-scan-help-section></lib-scan-help-section>
              </div>
            </div>

            <div class="col-md-6">
              <div>
                <lib-scan-single-fingerprint (fingerprintEvent)="getFingerCaptureEvent($event)"
                                             #singleFingerprint></lib-scan-single-fingerprint>

                <div class="d-flex justify-content-between gap-3">
                  <button (click)="logout()" *ngIf="showLoginButton" class="btn py-3 w-100 btn-secondary mt-3">
                    Cancel
                  </button>

                  <button [disabled]="_disableLoginButton" (click)="login()" *ngIf="showLoginButton"
                          class="btn py-3 w-100 btn-primary mt-3"
                          style="background: #1551AA;">
                    Login
                  </button>
                </div>

                <button (click)="bypass()" *ngIf="!environment.production"
                        class="btn py-3 btn-primary col-12 mt-3">
                  Bypass
                </button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>


