<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title"><span class="iconify text-warning" data-icon="fluent:warning-24-regular"></span> Confirm
      action</h5>
    <button type="button" class="btn-close"  (click)="close()" aria-label="Close"></button>
  </div>
  <div class="modal-body">
    <p class="mb-3">You are about the updated the settings value for <span class="fw-bold">
        {{setting.name | underscoreToSpace}}</span> from <span class="fw-bold">{{setting.value}}</span> to <span
      class="fw-bold">{{newValue}}</span>.</p>
    <p>Kindly click “save and update” to confirm this action.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="confirm()">Save and
      update
    </button>
  </div>
</div>