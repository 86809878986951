<div class="modal-header">
    <h5 class="modal-title d-flex align-items-center">
        <span class="iconify text-warning me-2" data-icon="fluent:warning-24-regular"></span> {{modalHeader}}
    </h5>
    <button type="button" class="btn-close" (click)="close()" aria-label="Close"></button>
</div>
<div class="modal-body">
    <alert *ngIf="errorMessage" [type]="'danger'" dismissOnTimeout="10000">{{errorMessage}}</alert>
    <div class="mb-3">
        <div [innerHTML]="infoMessage"></div>
        <div class="col-md-12 mb-3">
            <div *ngIf="reasonNeeded" class="form-group">
                <label> <b>Reason </b> </label>
                <textarea class="form-control" [(ngModel)]="disapprovalReason" placeholder="Enter Reason"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button  class="btn btn-danger" (click)="close()" *ngIf="showCloseButton"> {{cancelLabel}}</button>
    <button  [class]="'btn ' + confirmButtonClass" (click)="confirm()" [disabled]="isLoading">
        <span *ngIf="dataIcon" class="iconify icon" [attr.data-icon]="dataIcon"></span>
        {{confirmLabel}}
        <ng-container *ngIf="isLoading"><span><span class="iconify icon" data-icon="eos-icons:loading"></span></span></ng-container>
    </button>
</div>
