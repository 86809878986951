import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserModule} from '@angular/platform-browser';
import {KeycloakAngularModule, KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {LottieModule} from 'ngx-lottie';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ForbiddenComponent} from "./modules/extranet/error-pages/forbidden/forbidden.component";
import {NotFoundComponent} from './modules/extranet/error-pages/not-found/not-found.component';
import {SiteHeaderComponent} from './modules/layout/site-header/site-header.component';
import {SiteFooterComponent} from './modules/layout/site-footer/site-footer.component';
import {HttpInterceptorService} from "./shared/authentication/http-interceptor.service";
import {AuthenticationService} from "./shared/authentication/authentication-service.service";
import {LandingPageComponent} from './modules/extranet/landing-page/landing-page.component';
import {environment} from "../environments/environment";
import {HomeComponent} from './modules/extranet/home/home.component';
import {IntranetSideHeaderComponent} from './modules/layout/intranet-site-header/intranet-side-header.component';
import {BASE_PATH} from "../../sdk/vrps-api-sdk";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {DatePipe} from "@angular/common";
import {SharedModule} from "./shared/shared.module";
import {FingerprintAuthModule} from "./modules/extranet/fingerprint-auth/fingerprint-auth.module";

function initializeKeycloak(keycloak: KeycloakService,
                            authenticationService: AuthenticationService) {
    console.log('initia;ize')
    keycloak.keycloakEvents$.subscribe(async (value) => {
        console.log('initia;ize 1: ', value)

        if (value.type === KeycloakEventType.OnAuthSuccess) {

            await authenticationService.fetchUser().toPromise();
        }
    });
    return () =>
        keycloak.init({
            config: environment.keycloakConfig,
            initOptions: {
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri:
                    window.location.origin + '/assets/silent-check-sso.html',
                messageReceiveTimeout: 500_000,
            }
        });
}

export function playerFactory() {
    return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

@NgModule({
    declarations: [AppComponent, ForbiddenComponent, SiteHeaderComponent, SiteFooterComponent, LandingPageComponent, HomeComponent, IntranetSideHeaderComponent, NotFoundComponent],
    imports: [
        AppRoutingModule, BrowserModule,
        KeycloakAngularModule, HttpClientModule, FormsModule, ReactiveFormsModule,
        BrowserAnimationsModule,
        LottieModule.forRoot({player: playerFactory}), SharedModule,
        FingerprintAuthModule.forRoot()
    ],
    providers: [
        DatePipe,
        {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
        {
            provide: BASE_PATH,
            useValue: environment.apiBaseUrl
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService, AuthenticationService]
        }
    ],
    exports: [],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
