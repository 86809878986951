import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-multi-image-preview',
  templateUrl: './multi-image-preview.component.html',
  styleUrls: ['./multi-image-preview.component.css']
})
export class MultiImagePreviewComponent implements OnInit {

  @Input() title = 'Success';
  @Input() defaultImage = 'Action was successful';
  @Input() selectedImage = 'Action was successful';
  /**
   * @deprecated use close event instead
   */
  @Input() onClose: () => void;
  @Output() closeEvent = new EventEmitter<void>();

  constructor(private modalRef: BsModalRef) {}

  close(): void {
    if (this.onClose) {
      this.onClose();
    }
    this.closeEvent.emit();
    this.modalRef.hide();
  }

  ngOnInit(): void {
  }

}
