import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Setting} from "../../../../sdk/vrps-api-sdk";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-setting-confirmation-dialog',
  templateUrl: './setting-confirmation-dialog.component.html',
  styleUrls: ['./setting-confirmation-dialog.component.css']
})
export class SettingConfirmationDialogComponent implements OnInit {

  @Input()
  setting: Setting;
  @Input()
  newValue: string;

  @Output()
  onConfirm = new EventEmitter();
  onClose = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,) {
  }

  ngOnInit(): void {
  }

  confirm() {
    this.onConfirm.emit();
    // if (this.dismissOnConfirm) {
    this.modalRef.hide();

  }

  close() {
    this.onClose.emit();
    this.modalRef.hide();
  }

}
