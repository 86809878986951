import { Component, OnInit } from '@angular/core';
import {User} from "../../../shared/models/user/user.model";
import {UserAccount} from "../../../shared/models/user-account.model";
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {KeycloakService} from "keycloak-angular";
import {PageManager} from "../../../shared/misc/page-manager";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-intranet-side-header',
  templateUrl: './intranet-side-header.component.html',
  styleUrls: ['./intranet-side-header.component.css']
})
export class IntranetSideHeaderComponent implements OnInit {

  user: User;
  currentAccount: UserAccount;
  routeData: any;

  constructor(private authenticationService: AuthenticationService,
              private router: Router, route: ActivatedRoute,
              private keycloak: KeycloakService, private pageManager: PageManager) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.firstChild?.snapshot?.data || route?.root?.firstChild?.snapshot?.data;        
      }
    })
  }

  ngOnInit(): void {
    this.pageManager.currentUserAccount$
      .subscribe(userAccount => {
        this.currentAccount = userAccount;
      });
    this.authenticationService.getUser()
      .subscribe(value => {
        this.user = value;
        if (this.currentAccount) {
          if (this.user && this.user.accounts.some(u => u.code === this.currentAccount.code)) {
            this.pageManager.setIntegration(this.user.accounts.find(u => u.code === this.currentAccount.code));
          }
          this.pageManager.currentUserAccount$.next(this.currentAccount);
        } else if (this.user) {
          this.currentAccount = this.user.accounts[0];
          this.pageManager.setCurrentUserAccount(this.user.accounts[0]);
        }

      });
    this.init();
  }
  init(){
    // window.localStorage.getItem("PQR_showSidebar")
    //   ? window.localStorage.getItem("PQR_showSidebar").toLowerCase() == "true"
    //     ? null
    //     : document.getElementById("body-pd").classList.add("body-pd")
    //   : document.getElementById("body-pd").classList.remove("body-pd");
  }

  // toggle(){
  //   let navBar = document.getElementById("nav-bar");
  //   if (navBar) {
  //     navBar.classList.toggle("show");
  //   }
  //   let bodyPd = document.getElementById("body-pd");
  //   if (bodyPd) {
  //     bodyPd.classList.toggle("body-pd");
  //   }
  //   if (window.innerWidth > 768) {
  //     if (navBar) {
  //       if (navBar.classList.contains("show")) {
  //         localStorage.setItem("PQR_showSidebar", "true");
  //       } else {
  //         localStorage.setItem("PQR_showSidebar", "false");
  //       }
  //     } else {
  //       localStorage.setItem("PQR_showSidebar", "false");
  //     }
  //   }
  // }

  async logout() {
    await this.keycloak.getKeycloakInstance().logout({})
  }

}
