/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AuditTrailDto { 
    ipAddress?: string;
    entityName?: string;
    recordId?: number;
    transactionId?: string;
    recordType?: AuditTrailDto.RecordTypeEnum;
    eventType?: AuditTrailDto.EventTypeEnum;
    actorEventTime?: string;
    actorEmail?: string;
    actorUUID?: string;
    recipientEmail?: string;
    recipientUUID?: string;
    description?: string;
    actorRole?: Set<string>;
    actorPermission?: Set<string>;
    eventStatus?: AuditTrailDto.EventStatusEnum;
    resource?: string;
    resourceUri?: string;
    userAgent?: string;
    externalReference?: string;
    otherDetails?: string;
    authenticationMethodConstant?: AuditTrailDto.AuthenticationMethodConstantEnum;
    attemptedAttackType?: AuditTrailDto.AttemptedAttackTypeEnum;
    maskedNin?: string;
    attemptedAttack?: boolean;
    lastLoginTime?: string;
}
export namespace AuditTrailDto {
    export type RecordTypeEnum = 'ACCOUNT_CREATION' | 'PORTAL_USER_CREATION' | 'PORTAL_USER_DELETION' | 'PORTAL_USER_DEACTIVATION' | 'ACCEPT_TERMS_OF_USE' | 'PORTAL_USER_ABSENCE' | 'PORTAL_USER_ACTIVATION' | 'PORTAL_USER_ROLE_UPDATE' | 'USER_LOGIN' | 'USER_LOGOUT' | 'USER_LOGIN_ERROR' | 'REPLAY_ATTACK' | 'MITM_ATTACK' | 'USER_PASSWORD_RESET' | 'SERVER_LOGIN' | 'SERVER_LOGOUT' | 'OTP_VALIDATION_SUCCESS' | 'OTP_VALIDATION_FAILURE' | 'OTP_GENERATION' | 'ACTIVITY_LOG' | 'SEARCH_USER' | 'VIEW_USER' | 'UPDATE_PROFILE_IMAGE' | 'VIEW_PROFILE_IMAGE' | 'SETTING_MANAGEMENT' | 'PERSON_MANAGEMENT' | 'REQUEST_MANAGEMENT' | 'DASHBOARD_MANAGEMENT' | 'UPDATE_SETTING' | 'CREATE_SETTING' | 'SEARCH_SETTING' | 'CREATE_USER' | 'DEACTIVATE_USER' | 'ACTIVATE_USER' | 'VIEW_USER_DETAILS' | 'REMOVE_ROLE' | 'ADD_ROLE';
    export const RecordTypeEnum = {
        AccountCreation: 'ACCOUNT_CREATION' as RecordTypeEnum,
        PortalUserCreation: 'PORTAL_USER_CREATION' as RecordTypeEnum,
        PortalUserDeletion: 'PORTAL_USER_DELETION' as RecordTypeEnum,
        PortalUserDeactivation: 'PORTAL_USER_DEACTIVATION' as RecordTypeEnum,
        AcceptTermsOfUse: 'ACCEPT_TERMS_OF_USE' as RecordTypeEnum,
        PortalUserAbsence: 'PORTAL_USER_ABSENCE' as RecordTypeEnum,
        PortalUserActivation: 'PORTAL_USER_ACTIVATION' as RecordTypeEnum,
        PortalUserRoleUpdate: 'PORTAL_USER_ROLE_UPDATE' as RecordTypeEnum,
        UserLogin: 'USER_LOGIN' as RecordTypeEnum,
        UserLogout: 'USER_LOGOUT' as RecordTypeEnum,
        UserLoginError: 'USER_LOGIN_ERROR' as RecordTypeEnum,
        ReplayAttack: 'REPLAY_ATTACK' as RecordTypeEnum,
        MitmAttack: 'MITM_ATTACK' as RecordTypeEnum,
        UserPasswordReset: 'USER_PASSWORD_RESET' as RecordTypeEnum,
        ServerLogin: 'SERVER_LOGIN' as RecordTypeEnum,
        ServerLogout: 'SERVER_LOGOUT' as RecordTypeEnum,
        OtpValidationSuccess: 'OTP_VALIDATION_SUCCESS' as RecordTypeEnum,
        OtpValidationFailure: 'OTP_VALIDATION_FAILURE' as RecordTypeEnum,
        OtpGeneration: 'OTP_GENERATION' as RecordTypeEnum,
        ActivityLog: 'ACTIVITY_LOG' as RecordTypeEnum,
        SearchUser: 'SEARCH_USER' as RecordTypeEnum,
        ViewUser: 'VIEW_USER' as RecordTypeEnum,
        UpdateProfileImage: 'UPDATE_PROFILE_IMAGE' as RecordTypeEnum,
        ViewProfileImage: 'VIEW_PROFILE_IMAGE' as RecordTypeEnum,
        SettingManagement: 'SETTING_MANAGEMENT' as RecordTypeEnum,
        PersonManagement: 'PERSON_MANAGEMENT' as RecordTypeEnum,
        RequestManagement: 'REQUEST_MANAGEMENT' as RecordTypeEnum,
        DashboardManagement: 'DASHBOARD_MANAGEMENT' as RecordTypeEnum,
        UpdateSetting: 'UPDATE_SETTING' as RecordTypeEnum,
        CreateSetting: 'CREATE_SETTING' as RecordTypeEnum,
        SearchSetting: 'SEARCH_SETTING' as RecordTypeEnum,
        CreateUser: 'CREATE_USER' as RecordTypeEnum,
        DeactivateUser: 'DEACTIVATE_USER' as RecordTypeEnum,
        ActivateUser: 'ACTIVATE_USER' as RecordTypeEnum,
        ViewUserDetails: 'VIEW_USER_DETAILS' as RecordTypeEnum,
        RemoveRole: 'REMOVE_ROLE' as RecordTypeEnum,
        AddRole: 'ADD_ROLE' as RecordTypeEnum
    };
    export type EventTypeEnum = 'CREATE' | 'DELETE' | 'UPDATE' | 'SELECT' | 'LOGIN' | 'LOGOUT' | 'OPERATION_LOG' | 'GENERIC_LOG' | 'SIEM' | 'BIOMETRIC_LOGIN';
    export const EventTypeEnum = {
        Create: 'CREATE' as EventTypeEnum,
        Delete: 'DELETE' as EventTypeEnum,
        Update: 'UPDATE' as EventTypeEnum,
        Select: 'SELECT' as EventTypeEnum,
        Login: 'LOGIN' as EventTypeEnum,
        Logout: 'LOGOUT' as EventTypeEnum,
        OperationLog: 'OPERATION_LOG' as EventTypeEnum,
        GenericLog: 'GENERIC_LOG' as EventTypeEnum,
        Siem: 'SIEM' as EventTypeEnum,
        BiometricLogin: 'BIOMETRIC_LOGIN' as EventTypeEnum
    };
    export type EventStatusEnum = 'SUCCESSFUL' | 'FAILED';
    export const EventStatusEnum = {
        Successful: 'SUCCESSFUL' as EventStatusEnum,
        Failed: 'FAILED' as EventStatusEnum
    };
    export type AuthenticationMethodConstantEnum = 'PASSWORD' | 'FINGERPRINT';
    export const AuthenticationMethodConstantEnum = {
        Password: 'PASSWORD' as AuthenticationMethodConstantEnum,
        Fingerprint: 'FINGERPRINT' as AuthenticationMethodConstantEnum
    };
    export type AttemptedAttackTypeEnum = 'REPLAY' | 'MITM';
    export const AttemptedAttackTypeEnum = {
        Replay: 'REPLAY' as AttemptedAttackTypeEnum,
        Mitm: 'MITM' as AttemptedAttackTypeEnum
    };
}


