/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EtlStatisticsPojo { 
    processedRecords?: number;
    recordsFlagged?: number;
    recordsWithoutCentralRecordId?: number;
    ninWithFalseStateTransition?: number;
    recordsAwaitingEtl?: number;
    totalRecordsInCurrentBatch?: number;
    overallProgressPercentage?: number;
    currentBatchProgressPercentage?: number;
    totalRecordsInCorpus?: number;
    processableRecordsInCorpus?: number;
    concurrentThreadCount?: number;
}

