<div class="modal-content">
  <div class="modal-header border-bottom-0 d-flex align-items-center">
    <h4>{{header}}</h4>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>
  <div class="modal-body">
    <div>
      <p>{{body}}</p>
      <p class="fw-bold">Are you sure you want to continue?</p>
    </div>

    <div class="mt-4">
      <form id="form" [formGroup]="form" class="form-group" (validSubmit)="confirm()">

          <div class="form-control border-0">
            <label  class="form-label">Reason</label>
            <textarea
              class="form-control"
              formControlName="reason"
              name="reason"
              id="reason"
              cols="30"
              rows="2"
            ></textarea>
          <bfv-messages></bfv-messages>
        </div>

      </form>

    </div>
  </div>
  <div class="modal-footer border-top-0">
    <button
      type="button"
      class="btn btn-outline-secondary dasboard-btn align-items-center"
      (click)="close()">
      Cancel
    </button>
    <button type="submit" form="form" class="btn btn-danger dasboard-btn">
      {{positiveButtonText}}
    </button>
  </div>
</div>
