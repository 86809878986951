<div class="p-3">
  <div class="d-flex align-items-center">
    <p class="mb-0 me-2">Show</p>
    <div class="px-2 py-md-1 mx-2 rounded form-group">
      <select class=" border-0" [(ngModel)]="pageLength">
        <option [selected]="i == 0" *ngFor="let val of pageLengths; let i = index" [value]="val">
          {{val}}
        </option>
      </select>
    </div>
    <p class="mb-0 ms-2">per page</p>
  </div>
</div>
