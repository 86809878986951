/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EtlAdjudicationSearchFilter { 
    offset?: number;
    limit?: number;
    order?: EtlAdjudicationSearchFilter.OrderEnum;
    orderColumn?: string;
    centralRecordId?: string;
    nin?: string;
    startDate?: string;
    endDate?: string;
    firstName?: string;
    lastName?: string;
    approvalStatus?: EtlAdjudicationSearchFilter.ApprovalStatusEnum;
    dateOfBirth?: string;
    phoneNumber?: string;
}
export namespace EtlAdjudicationSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        Approved: 'APPROVED' as ApprovalStatusEnum,
        Pending: 'PENDING' as ApprovalStatusEnum,
        Disapproved: 'DISAPPROVED' as ApprovalStatusEnum
    };
}


