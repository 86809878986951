import {Component, OnInit} from '@angular/core';
import {User} from "../../../shared/models/user/user.model";
import {AuthenticationService} from "../../../shared/authentication/authentication-service.service";
import {KeycloakService} from "keycloak-angular";
import {PageManager} from "../../../shared/misc/page-manager";
import {UserAccount} from "../../../shared/models/user-account.model";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AccountMembershipPojo} from "../../../../../sdk/vrps-api-sdk";
import AccountTypeEnum = AccountMembershipPojo.AccountTypeEnum;

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.css']
})
export class SiteHeaderComponent implements OnInit {
  user: User;
  currentAccount: UserAccount;
  vrpsAccount: UserAccount;
  routeData: any;

  constructor(private authenticationService: AuthenticationService,
              private router: Router, route: ActivatedRoute,
              private keycloak: KeycloakService, private pageManager: PageManager) {
    router.events.forEach(e => {
      if (e instanceof NavigationEnd) {
        this.routeData = route?.root?.firstChild?.snapshot?.data;
      }
    })
  }

  ngOnInit(): void {
    this.pageManager.currentUserAccount$
      .subscribe(userAccount => {
        this.currentAccount = userAccount;
      });
    this.authenticationService.getUser()
      .subscribe(value => {
        this.user = value;
        this.vrpsAccount = this.user?.accounts.filter(a => a.accountCode.includes('VRPS'))[0];
        if (this.currentAccount) {
          if (this.user && this.user.accounts.some(u => u.code === this.currentAccount.code)) {
            this.pageManager.setIntegration(this.user.accounts.find( u => u.code === this.currentAccount.code));
          }
          this.pageManager.currentUserAccount$.next(this.currentAccount);
        } else if (this.user) {
          this.currentAccount = this.user.accounts[0];
          this.pageManager.setCurrentUserAccount(this.user.accounts[0]);
        }

      });
  }

  async login() {
    this.authenticationService.login({

      redirectUri: window.location.origin + '/configuration',
      prompt: 'login'

    });
  }

  async dashboard() {
    this.router.navigateByUrl('/dashboard')
  }

  logout() {
    this.authenticationService.logout().subscribe(value => {

    })
  }

  get AccountType() {
    return AccountTypeEnum
  }

  goToDashboard() {
    this.router.navigate(['/dashboard'])
  }

  setHeaderClass() {
    let headerClass = '';

    if (this.routeData?.fixedHeader) {
      headerClass += ' header-fixed fixed-on-scroll'
    }
    if (!(this.routeData?.isExtranet)) {
      headerClass += ' intranet-nav'
    }

    return headerClass;
  }
}
