/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EtlErrorSearchFilter { 
    offset?: number;
    limit?: number;
    order?: EtlErrorSearchFilter.OrderEnum;
    orderColumn?: string;
    nin?: string;
    flagType?: EtlErrorSearchFilter.FlagTypeEnum;
}
export namespace EtlErrorSearchFilter {
    export type OrderEnum = 'ASC' | 'DESC';
    export const OrderEnum = {
        Asc: 'ASC' as OrderEnum,
        Desc: 'DESC' as OrderEnum
    };
    export type FlagTypeEnum = 'ERROR_APPLICATION' | 'SUSPENDED_PROCESSING_ERROR' | 'ERROR_NIN' | 'UNABLE_TO_CREATE_PERSON_RECORD' | 'INVALID_APPLICATION_STATE_TRANSITION';
    export const FlagTypeEnum = {
        ErrorApplication: 'ERROR_APPLICATION' as FlagTypeEnum,
        SuspendedProcessingError: 'SUSPENDED_PROCESSING_ERROR' as FlagTypeEnum,
        ErrorNin: 'ERROR_NIN' as FlagTypeEnum,
        UnableToCreatePersonRecord: 'UNABLE_TO_CREATE_PERSON_RECORD' as FlagTypeEnum,
        InvalidApplicationStateTransition: 'INVALID_APPLICATION_STATE_TRANSITION' as FlagTypeEnum
    };
}


