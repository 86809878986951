import {PermissionType} from '../permission-type.enum';
import {UserAccount} from '../user-account.model';


export class User {

  public accounts!: UserAccount[];
  public email!: string;
  public firstName!: string;
  public lastName!: string;
  public surname!: string;
  public phone!: string;
  public requiresPasswordUpdate!: boolean;
  public photoId!:number;
  public userId!: string;
  public status!: string;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.accounts) {
      this.accounts = data.accounts;
    }
  }

  public permissions(): string[] {
    if (!this.accounts || this.accounts == []) {
      return [];
    }
    // const roles: string[] = this.accounts[0]?.roles;
    let permits = [] ;
    permits = this.accounts[0]?.permissions;
    return permits.filter((v, i) => permits.indexOf(v) === i);
  }

  public hasPermission(permissionName: string | PermissionType) {
    return (this.permissions() || []).filter((it: string) => it === permissionName).length;
  }


  public hasRole(roleName: string) {
    const accounts: UserAccount[] = this.accounts.filter((acct: any) => {
      return acct.roles.filter((it: string) => it === roleName).length;
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }
}
