/**
 * VRPS API
 * The VRPS RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: airekeola@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Setting { 
    id?: number;
    name: string;
    value?: string;
    description?: string;
    createdAt?: string;
    editable?: boolean;
    valueEncrypted?: boolean;
    encryptionVersion?: string;
}

