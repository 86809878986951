import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-success-dialog-with-lottie',
  templateUrl: './success-dialog-with-lottie.component.html',
  styleUrls: ['./success-dialog-with-lottie.component.css']
})
export class SuccessDialogWithLottieComponent implements OnInit {


  @Input() title = 'Success';
  @Input() message = 'Action was successful';
  /**
   * @deprecated use close event instead
   */
  @Input() onClose: () => void;
  lottieIconPath = 'assets/lottie/success.json';
  @Output() closeEvent = new EventEmitter<void>();

  constructor(private modalRef: BsModalRef) {}

  close(): void {
    if (this.onClose) {
      this.onClose();
    }
    this.closeEvent.emit();
    this.modalRef.hide();
  }

  ngOnInit(): void {
  }

}
