<div class="box">
  <div class="card"
       style="border-color: #173D7A; border: 1px solid rgba(0, 82, 204, 0.13); background: rgba(23, 61, 122, 0.05);">
    <div style="color: #173D7A;" class="mx-2 my-2 text-center fw-semibold fs-12">
      {{ config?.captureInstruction }}
    </div>
  </div>
  <div class="mt-2 row col-gap-3">
    <div class="col-md-6 col-6 text-center fw-semibold fs-12">
      {{ config?.leftHandLabel }}
    </div>
    <div class="col-md-6 col-6 text-center fw-semibold fs-12">
      {{ config?.rightHandLabel }}
    </div>
  </div>
  <div class="card">
    <div class="card-bodyx">
      <div class="mt-1 mb-1 row row-gap-3">
        <div class="col-12 text-center">
          <div class="" style="margin: 0 auto;">
            <app-fingerprint-svg [capturedFingers]="capturedFingers"
                                 [currentCaptureIndex]="toScan"></app-fingerprint-svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class=" text-center">

    <div
      [class.alert-success]="getMessageType() === AlertType.success"
      [class.alert-danger]="getMessageType() === AlertType.error"
      [class.alert-primary]="getMessageType() === AlertType.primary"
      [class.bg]="getMessageType() === AlertType.info"
      class="alert border-0 rounded-2 fs-12 mt-3 text-center fw-bold"
    >
      {{ getAlertMessage() }}
    </div>

    <div class="mt-3 d-flex align-items-center justify-content-center flex-column h-100 text-center"
    >

      <div class="progress-container">
        <lib-circular-progress [percent]="percent" [outerStrokeColor]="progressColor"></lib-circular-progress>

        <ng-container *ngIf="!captureStarted
                            && !scanResult
                            && !showVerificationFailure
                            && !verifying">
          <div class="center-content">
            <i class="iconify mt-auto mb-auto opacity-25" style="font-size: 50px;" data-inline="false"
               data-icon="ion:finger-print"></i>
          </div>
        </ng-container>

        <ng-container *ngIf="!fpConnected && pluginDetected">
          <div class="center-content">
            <i class="iconify mt-auto mb-auto" style="font-size: 50px; color: rgba(255, 68, 45, 0.10)"
               data-inline="false"
               data-icon="material-symbols-light:fingerprint-off-sharp"></i>
          </div>
        </ng-container>

        <ng-container *ngIf="showVerificationFailure">
          <div class="center-content">
            <i class="iconify mt-auto mb-auto opacity-25" style="font-size: 50px; color: rgba(255, 68, 45, 0.10)"
               data-inline="false"
               data-icon="ion:finger-print"></i>
          </div>
        </ng-container>

        <ng-container *ngIf="verifying">
          <div class="center-content">
            <ng-lottie
              [options]="{ path: './assets/lottie/yellow_loader.json', loop: true, autoplay: true }"
              width="50px" height="50px" containerClass="mx-auto">
            </ng-lottie>
          </div>
        </ng-container>

        <ng-container *ngIf="captureStarted
                            && !scanResult
                            && !showVerificationFailure
                            && !verifying">
          <div class="center-content">
            <ng-lottie
              [options]="{ path: './assets/lottie/finger-print-scanner.json', loop: true, autoplay: true }"
              width="50px" height="50px" containerClass="mx-auto">
            </ng-lottie>
          </div>
        </ng-container>

        <ng-container *ngIf="!captureStarted
                            && scanResult
                            && !verifying" class="position-relative">
          <div class="center-content">
            <ng-lottie
              [options]="{ path: './assets/lottie/finger-print-scanner-success.json', loop: false, autoplay: true }"
              width="80px" height="80px" containerClass="mx-auto"></ng-lottie>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
  <button [disabled]="scanButtonDisabled" *ngIf="showScanButton" (click)="startScan()"
          class="btn btn-primary w-100 mt-3">
    <i class="iconify icon" data-icon="lucide:scan-line"></i>
    Scan
  </button>
</div>
