<section class="hero-bg">
  <div class="container py-5 mt-md-5">
    <div class="row pt-5 mt-5 align-items-center">
      <div class="col-lg-7">
        <div class="mx-auto" style="max-width: 660px;">
          <h1 class="mb-4 fw-bold" style="font-size: calc(2rem + 1.8vw)!important;">Welcome to the VRPS setup wizard!</h1>

          <p class="mb-3">
            To succeed ensure service is running on the same network segment as
            the following systems:
          </p>
          <ol class="ps-4">
            <li class="mb-1">Source database.</li>
            <li class="mb-1">Destination database.</li>
            <li class="mb-1">Power source is steady throughout the FTL process.</li>
          </ol>

          <div class="my-5">
            <p class="text-link mb-0">
              <span class="iconify me-2 fs-28" data-icon="emojione:warning"></span>
              Check Firewall Settings To Ensure Ports Are Not Blocked.
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="hero-img-wrapper text-center">
          <img src="/assets/img/etlIcon.svg" class="img-fluid mb-4" alt="" />
          <div class="text-header">
            Fetching Transformation and <strong> LOAD </strong>Tool
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
