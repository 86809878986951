import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../custom-validators";

@Component({
  selector: 'app-reason-for-disapproval-dialog',
  templateUrl: './reason-for-disapproval-dialog.component.html',
  styleUrls: ['./reason-for-disapproval-dialog.component.css']
})
export class ReasonForDisapprovalDialogComponent implements OnInit {
  @Input() header = 'Disapprove';
  @Input() body = 'Are you sure you want to disapprove?';
  @Input() positiveButtonText = 'Save';
  @Input() dismissOnSave = true;
  @Input() showLoadingOnSaveClick = true;
  @Output()
  onSaveClicked = new EventEmitter<string>();
  @Input() loading = false;

  form: FormGroup;

  constructor(
    public modalRef: BsModalRef, public formBuilder: FormBuilder) {
  }


  ngOnInit(): void {
    this.form=this.formBuilder.group({
      'reason': ['', [Validators.required,CustomValidators.notBlank]]
    })
  }

  close() {
    this.modalRef.hide();
  }

  confirm() {
    console.log('click clcikk: ')
    if (this.showLoadingOnSaveClick) {
      this.loading = true;
    }
    this.onSaveClicked.emit( this.form.get('reason').value);
    console.log('review message inside: ', this.onSaveClicked)
    if (this.dismissOnSave) {
      this.modalRef.hide();
    }
  }
}
