import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {UnderscoreToSpacePipe} from './pipes/underscore-to-space.pipe';
import {TimeOnlyPipe} from './pipes/time-only.pipe';
import {DateOnlyPipe} from './pipes/date-only.pipe';
import {DatetimePipe} from './pipes/datetime.pipe';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {RemoveUnderscoresPipe} from './pipes/remove-underscores.pipe';
import {NumberStringPipe} from './pipes/number-string.pipe';
import {TruncatePipe} from './pipes/truncate.pipe';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {FullPageLoaderComponent} from './components/full-page-loader/full-page-loader.component';
import {AmountPipe} from "./pipes/amount.pipe";
import {FullDateTimePipe} from './pipes/full-date-time.pipe';
import {LoaderComponent} from './components/loader/loader.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import { ReasonForDisapprovalDialogComponent } from './components/reason-for-disapproval-dialog/reason-for-disapproval-dialog.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import {SuccessDialogComponent} from "./success-dialog/success-dialog.component";
import {SettingConfirmationDialogComponent} from "./setting-confirmation-dialog/setting-confirmation-dialog.component";
import {LottieModule} from "ngx-lottie";
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SuccessResultDialogComponent } from './success-result-dialog/success-result-dialog.component';
import {AlertModule} from "ngx-bootstrap/alert";
import { SuccessDialogWithLottieComponent } from './components/success-dialog-with-lottie/success-dialog-with-lottie.component';
import { MultiImagePreviewComponent } from './components/multi-image-preview/multi-image-preview.component';
import {ButtonWithLoaderComponent} from "./components/button-with-loader/button-with-loader.component";
import {TotalRecordsComponent} from "./components/total-records/total-records.component";
import {PageSizeComponent} from "./components/page-size/page-size.component";
import {FingerprintAuthenticationModule} from "./fingerprint-authentication/fingerprint-authentication.module";


@NgModule({
    declarations: [
        UnderscoreToSpacePipe,
        TimeOnlyPipe,
        DateOnlyPipe,
        AmountPipe,
        DatetimePipe,
        RemoveUnderscoresPipe,
        NumberStringPipe,
        LoaderComponent,
        FullPageLoaderComponent,
        TruncatePipe,
        ConfirmDialogComponent,
        FullDateTimePipe,
        ReasonForDisapprovalDialogComponent,
        SkeletonLoaderComponent,
        SuccessDialogComponent,
        SettingConfirmationDialogComponent,
        ConfirmationDialogComponent,
        SuccessResultDialogComponent,
        SuccessDialogWithLottieComponent,
        MultiImagePreviewComponent,
        ButtonWithLoaderComponent,
        TotalRecordsComponent,
        PageSizeComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ImageCropperModule,
        ModalModule.forRoot(),
        ProgressbarModule.forRoot(),
        TooltipModule.forRoot(),
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TypeaheadModule.forRoot(),
        NgBootstrapFormValidationModule.forRoot(),
        BsDropdownModule.forRoot(),
        LottieModule,
        AlertModule
    ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        ProgressbarModule,
        TooltipModule,
        PaginationModule,
        BsDatepickerModule,
        ConfirmDialogComponent,
        UnderscoreToSpacePipe,
        TimeOnlyPipe,
        DateOnlyPipe,
        DatetimePipe,
        RemoveUnderscoresPipe,
        NumberStringPipe,
        TruncatePipe,
        NgBootstrapFormValidationModule,
        BsDropdownModule,
        AmountPipe,
        FullDateTimePipe,
        LoaderComponent,
        FullPageLoaderComponent,
        SkeletonLoaderComponent,
        SuccessDialogComponent,
        SettingConfirmationDialogComponent,
        ButtonWithLoaderComponent,
        TotalRecordsComponent,
        PageSizeComponent
    ],schemas:[ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {
}
